<template>
  <div class="pt-10">
    <vx-card>
      <div class="vx-breadcrumb md:block hidden">
        <ul class="flex flex-wrap items-center">
          <li class="inline-flex items-end">
            <router-link :to="{ name: 'director-learning-centre-list'}">Manage</router-link>
            <span class="breadcrumb-separator mx-2">
              <span class="feather-icon select-none relative">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-chevrons-right"
                >
                  <polyline points="13 17 18 12 13 7"/>
                </svg>
              </span>
            </span>
          </li>
          <li class="inline-flex items-center">
            <router-link
              :to="{ name: 'director-learning-centre-view', params:{id: centerId } }"
            >{{ centerDetails.name }}
            </router-link>
            <span class="breadcrumb-separator mx-2">
              <span class="feather-icon select-none relative">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-chevrons-right"
                >
                  <polyline points="13 17 18 12 13 7"/>
                </svg>
              </span>
            </span>
          </li>

          <li class="inline-flex items-center">
            <router-link
              :to="{ name: 'director-learning-centre-view', params:{id: centerId } }"
            >Admin
            </router-link>
            <span class="breadcrumb-separator mx-2">
              <span class="feather-icon select-none relative">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-chevrons-right"
                >
                  <polyline points="13 17 18 12 13 7"/>
                </svg>
              </span>
            </span>
          </li>

          <li class="inline-flex items-center">
            <a href="javascript:void(0)" class>{{ centerDetails.name }}</a>
          </li>
        </ul>
      </div>

      <div class="w-full md:w-1/2">
        <div class="vx-col w-full my-8 vs-con-loading__container">
          <template v-if="logo">
            <!-- Image Container -->
            <div class="img-container w-64 flex items-center">
              <img :src="logo" alt="img" class="responsive"/>
            </div>

            <!-- Image upload Buttons -->
            <div class="modify-img flex mt-5">
              <input
                type="file"
                class="hidden"
                ref="updateImgInput"
                @change="updateCurrImg"
                accept="image/*"
              />
              <vs-button @click="$refs.updateImgInput.click()">Edit</vs-button>
              <vs-button @click="logo = null" class="ml-8" color="danger">Remove Photo</vs-button>
            </div>
          </template>

          <div class="upload-img" v-if="!logo">
            <input
              type="file"
              class="hidden"
              ref="uploadImgInput"
              @change="updateCurrImg"
              accept="image/*"
            />
            <vs-button @click="$refs.uploadImgInput.click()" type="filled">Upload Photo</vs-button>
          </div>
        </div>

        <!-- Title -->
        <div class="vx-col w-full mt-5">
          <div class="select-wrapper">
            <div class="vs-component is-label-placeholder">
              <v-select
                :options="titleOptions"
                :clearable="false"
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
                v-model="titleFilter"
                class="mb-4 md:mb-0"
                data-vv-as="Title"
              >
                <template #header>
                  <label for class="label">Title</label>
                </template>
                <template #open-indicator="{ attributes }">
                  <span v-bind="attributes">
                    <vs-icon icon="arrow_drop_down"></vs-icon>
                  </span>
                </template>
              </v-select>

            </div>
          </div>
        </div>

        <!-- NAME -->
        <vs-input
          label-placeholder="Full Name"
          v-model="fullName"
          class="w-full mt-5"
          name="fullName"
          v-validate="'required|max:150'"
          data-vv-as="Full Name"
        />
        <span class="text-danger" v-show="errors.has('fullName')">{{ errors.first('fullName') }}</span>

        <vs-input
          class="w-full mt-5"
          label-placeholder="email"
          v-validate="'required|email'"
          label="Email"
          v-model="email"
          data-vv-as="Email"
        />
        <span class="text-danger text-sm" v-show="errors.has('email')">{{ errors.first('email') }}</span>

        <vs-input
          label-placeholder="Phone"
          v-model="mobileNumber"
          class="mt-5 w-full"
          name="mobileNumber"
          v-validate="'required|numeric'"
          data-vv-as="Phone"
        />
        <span
          class="text-danger text-sm"
          v-show="errors.has('mobileNumber')"
        >{{ errors.first('mobileNumber') }}</span>
        <div class="vx-col w-full mt-5">
          <div class="select-wrapper">
            <div class="vs-component is-label-placeholder">
              <v-select
                :options="countryOptions"
                :clearable="false"
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
                v-model="countryFilter"
                class="mb-4 md:mb-0"
                data-vv-as="Contry"
              >
                <template #header>
                  <label for class="label">Country</label>
                </template>
                <template #open-indicator="{ attributes }">
                  <span v-bind="attributes">
                    <vs-icon icon="arrow_drop_down"></vs-icon>
                  </span>
                </template>
              </v-select>
            </div>
          </div>
        </div>

        <div class="vx-col w-full mt-5">
          <div class="select-wrapper">
            <div class="vs-component is-label-placeholder">
              <v-select
                :options="stateOptions"
                :clearable="false"
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
                v-model="stateFilter"
                class="mb-4 md:mb-0"
                data-vv-as="State"
              >
                <template #header>
                  <label for class="label">State</label>
                </template>
                <template #open-indicator="{ attributes }">
                  <span v-bind="attributes">
                    <vs-icon icon="arrow_drop_down"></vs-icon>
                  </span>
                </template>
              </v-select>
            </div>
          </div>
        </div>

        <vs-input
          label-placeholder="Street Address"
          v-model="streetAddress"
          class="mt-5 w-full"
          name="streetAddress"
          data-vv-as="Street Address"
        />
        <span
          class="text-danger text-sm"
          v-show="errors.has('streetAddress')"
        >{{ errors.first('streetAddress') }}</span>

        <vs-input
          label-placeholder="Town/City"
          v-model="town"
          class="mt-5 w-full"
          name="town"
          data-vv-as="Town"
        />
        <span class="text-danger text-sm" v-show="errors.has('town')">{{ errors.first('town') }}</span>
        <vs-input
          label-placeholder="Postcode"
          v-model="postCode"
          class="mt-5 w-full"
          name="postCode"
          data-vv-as="Post Code"
        />
        <span
          class="text-danger text-sm"
          v-show="errors.has('postCode')"
        >{{ errors.first('postCode') }}</span>

        <div class="w-full mt-5">
          <label class="vs-input--label">Account Status</label>
          <vs-switch v-model="userData.status"/>
          {{ userData.status ? 'Active' : 'Inactive' }}

        </div>
      </div>
      <div class="w-full md:w-1/2 mt-10">
        <div class="flex flex-wrap items-center justify-end" slot="footer">
          <vs-button color="danger" @click="cancelBtn">Cancel</vs-button>
          <vs-button class="ml-8" @click="submitData" :disabled="!isFormValid">Save</vs-button>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
import vSelect from "vue-select";
import {mapActions} from "vuex";

export default {
  data() {
    return {
      userData: {
        fullName: "",
        email: "",
        mobileNumber: "",
        state: "",
        country: "",
        status: true,
        title: "",
        streetAddress: "",
        town: "",
        postCode: "",
        image: null,
      },
      settings: {
        // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6,
      },
      fullName: "",
      email: "",
      mobileNumber: "",
      streetAddress: "",
      town: "",
      postCode: "",
      logo: null,
      image: null,
      status: false,
      countryOptions: [],
      stateOptions: [],
      countryFilter: {label: "All", value: "all"},
      stateFilter: {label: "All", value: "all"},
      titleFilter: {label: "Select Title", value: ""},
      titleOptions: [
        {label: "Mr.", value: "Mr."},
        {label: "Mrs.", value: "Mrs."},
        {label: "Miss.", value: "Miss."},
      ],
      title: '',
      centerId: null,
      centerDetails: {},
    };
  },
  props: {
    data: {
      type: Object,
      default: () => {
      },
    },
  },
  components: {
    vSelect,
  },
  watch: {
    countryFilter(obj) {
      this.stateOptions = obj.states;
    },

    titleFilter(obj) {
      this.title = obj.value;
    }
  },
  computed: {
    isFormValid() {
      return (
        !this.errors.any() &&
        this.fullName != "" &&
        this.email != "" &&
        this.mobileNumber != ""
      );
    },
  },
  methods: {
    ...mapActions("center", [
      "getDirectorCenterById",
      "addDirectorCenterAdmin",
      "getDirectorAllCountries",
    ]),
    async getDirectorCenterDetails(id) {
      this.$vs.loading();
      await this.getDirectorCenterById(id)
        .then(async (res) => {
          if (res.status === 200) {
            this.centerDetails = await res.data.data;
          }
          this.$vs.loading.close();
        })
        .catch((err) => {
          this.$vs.loading.close();
        });
    },
    cancelBtn() {
      this.$router.push({
        name: "director-learning-centre-view",
        params: {id: this.centerId},
      });
    },
    updateCurrImg(input) {
      if (input.target.files && input.target.files[0]) {
        this.image = input.target.files[0];
        var reader = new FileReader();
        reader.onload = (e) => {
          this.logo = e.target.result;
        };
        reader.readAsDataURL(input.target.files[0]);
      }
    },
    showMessage(title, message, color) {
      this.$vs.notify({
        title: title,
        text: message,
        iconPack: "feather",
        icon: "icon-alert-circle",
        color: color,
      });
    },

    async submitData() {
      await this.$validator.validateAll().then((result) => {
        if (result) {
          this.$vs.loading();
          let data = new FormData();

          data.append("fullName", this.fullName);
          data.append("mobileNumber", this.mobileNumber);
          data.append("email", this.email);
          data.append("logo", this.image);
          data.append("title", this.titleFilter.value);
          data.append("state", this.stateFilter.value);
          data.append("country", this.countryFilter.value);
          data.append("streetAddress", this.streetAddress);
          data.append("town", this.town);
          data.append("postCode", this.postCode);
          data.append("status", this.userData.status);
          data.append("centerId", this.centerId);

          let info = {
            details: data,
            centerId: this.centerId,
          };
          this.addDirectorCenterAdmin(info)
            .then(async (res) => {
              if (await res.status === 200) {
                this.$vs.loading.close();
                this.$vs.notify({
                  title: 'Success',
                  text: "Center Admin Created Successfully",
                  iconPack: 'feather',
                  icon: 'icon-alert-circle',
                  color: 'success'
                });

                this.$router.push({
                  name: "director-learning-centre-view",
                  params: {id: this.centerId},
                });
              }
            })
            .catch((err) => {
              this.$vs.loading.close();
              if (err.response.status === 422) {
                this.$vs.notify({
                  title: "Failure",
                  text: err.response.data.message,
                  iconPack: "feather",
                  icon: "icon-alert-circle",
                  color: "danger",
                });
              }
            });
        } else {
          if (this.logo == null) {
            this.customError = "Image field is required.";
            return;
          }
        }
      });
    },
  },
  created() {
    this.centerId = this.$route.params.id;
    this.getDirectorCenterDetails(this.centerId);

    this.getDirectorAllCountries()
      .then((res) => {
        this.countryOptions = this.$store.state.center.countryOptions;
      })
      .catch((err) => {
        //console.error(err)
      });
  },
};
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);
}
</style>
